<template>
  <color-text-btn type="primary" size="mini" class="filter-item line menu-btn-item" @click="to"> 显示设置 </color-text-btn>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    },

    //一键定制位置
    isPosRefer: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async to() {
      const id = this.data.referSize.id
      if (!this.isPosRefer) {
        return this.$router.push(`/product/maintain/referenceSize?id=${id}`)
      }
      return this.$router.push(`/product/maintain/posReferenceSize?id=${id}`)
    }
  }
}
</script>

<style></style>
